@tailwind base;
@tailwind components;
@tailwind utilities;

a {
	background: linear-gradient(to bottom, #9CA3AF 0%, #9CA3AF 100%);
	background-position: 0 100%;
	background-repeat: repeat-x;
	background-size: 1px 1px;
	text-decoration: none;
	font-style: italic;
	font-weight: 500;
}

a:hover {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .4s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%239CA3AF' stroke-width='1' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
	background-position: 0 100%;
	background-size: auto 5px;
	background-repeat: repeat-x;
	text-decoration: none;
}
